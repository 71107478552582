import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cloneDeep, countBy } from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AgendaItemDialogComponent, MeetingSelectDialogComponent, MilestoneDialogComponent } from 'src/app/components';
import { MilestoneStatus, ResourceType, TaskReviewStatus, TaskStatus } from 'src/app/enums';
import {
  AuthService,
  KeyControlsService,
  LinkedTaskService,
  MeetingService,
  ModalService,
  ProgressIndicatorService,
  ProjectService,
  ProjectTaskService,
  RemindersService,
} from 'src/app/services';
import { LinkedWOTask, Milestone, Task, User } from 'src/app/types';
import { getDefaultRanks, getRankBetween } from 'src/app/utils';

@Component({
  selector: 'app-milestone-task-container',
  templateUrl: './milestone-task-container.component.html',
  styleUrls: ['./milestone-task-container.component.scss'],
})
export class MilestoneTaskContainerComponent implements OnInit, OnDestroy {
  @Input() milestoneData: Milestone = {}; // The milestone data of this view
  @Input() taskSelectedFromEmitter: LinkedWOTask | Task = null;
  @Input() editTasks: boolean; // whether the tasks can be dragged
  @Input() projectManagerId: number;
  // @Input()
  // isExpanded: boolean;

  @Output() public isProcessingUpdatingTaskRank = new EventEmitter<boolean>();

  @Output()
  milestoneDeactivateEvent = new EventEmitter<Milestone>();

  @Output() public phaseUpdated = new EventEmitter();

  @Output() public taskClicked = new EventEmitter();

  isCreatingTask = false;

  isUpdatingTaskRank = false;

  milestoneStatus: MilestoneStatus;

  tasks: Array<Task | LinkedWOTask> = [];

  private currentUser: User;

  public isExpanded: boolean;

  private subscriptions: Subscription[] = [];

  constructor(
    private _keyControlsService: KeyControlsService,
    public projectService: ProjectService,
    private remindersService: RemindersService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    public authService: AuthService,
    public taskService: ProjectTaskService,
    private meetingService: MeetingService,
    private progressIndicatorService: ProgressIndicatorService,
    private modalService: ModalService,
    private linkedTaskService: LinkedTaskService,
    private location: Location
  ) {
    this.currentUser = this.authService.getLoggedInUser();
  }

  async ngOnInit() {
    this.isExpanded = !this.milestoneData.is_closed;
    if (this.milestoneData.id === null) {
      console.warn('Milestone view does not have an id assigned. Cannot get tasks');
      return;
    }

    this.tasks = this.milestoneData.tasks || [];
    if (this.milestoneData.linked_wo_tasks) {
      // grab the status of all tasks with a work_order_id but no work_order. This means the work order has been deleted, or the user has invalid access to GET it
      const woIdsToCheck = this.milestoneData.linked_wo_tasks
        .filter((task) => !task.work_order)
        .map((task) => +task.work_order_id);
      const deletedWorkOrders = await this.linkedTaskService.checkWorkOrderStatus(woIdsToCheck).toPromise();
      this.milestoneData.linked_wo_tasks.forEach((task) => {
        // if the associated work order isn't inaccessible to the user, display it
        if (!woIdsToCheck.includes(task.work_order_id) || deletedWorkOrders.includes(task.work_order_id)) {
          task.status_id = task.work_order?.status?.id;
          if (deletedWorkOrders.includes(task.work_order_id)) {
            task.title = 'Work Order Deleted';
          } else {
            task.title = task.work_order?.title;
          }
          task.due_date = task.work_order?.due_date;
          task.assigned_user_id = task.work_order?.assigned_user?.id;
          task.assigned_user_first_name = task.work_order?.assigned_user?.first_name;
          task.assigned_user_last_name = task.work_order?.assigned_user?.last_name;
          task.assigned_user_login_enabled = true;
          this.tasks.push(task);
        }
      });

      // verify the current selected task is available to show and close drawer if not
      if (
        this.taskSelectedFromEmitter?.milestone_id === this.milestoneData.id &&
        !this.tasks.find((t) => t.id === this.taskSelectedFromEmitter?.id)
      ) {
        this.clickedTask(null);
      }
    }

    const taskSelectedSubscription = this.taskService.taskSelectedEvent.subscribe((data) => {
      if (data) {
        this.isCreatingTask = false;
      }
    });
    this.subscriptions.push(taskSelectedSubscription);
    const milestoneTaskSubscription = this.taskService.milestoneTaskEvent.subscribe(async (updatedTask) => {
      if (updatedTask.milestone_id === this.milestoneData.id) {
        const taskIndex = this.milestoneData.tasks.findIndex((task) => task.id === updatedTask.id);
        this.milestoneData.tasks[taskIndex] = updatedTask;
        if (taskIndex < 0) {
          this.milestoneData.tasks.push(updatedTask);
        }
        this.refreshMilestoneStatus();
      }
    });
    this.subscriptions.push(milestoneTaskSubscription);

    const taskCreatedSubscription = this.projectService.taskCreatedEvent.subscribe((task) => {
      if (+this.milestoneData.id === +task?.milestone_id) {
        this.tasks.push(task);
      }
    });
    this.subscriptions.push(taskCreatedSubscription);

    const taskDeletedSubscription = this.projectService.deleteTaskEvent.subscribe((taskId) => {
      this.removeTaskComponent(taskId);
    });
    this.subscriptions.push(taskDeletedSubscription);

    const closeTask = this.taskService.closeTask.subscribe(({ close, taskId }) => {
      if (taskId) {
        this.projectService.deleteTaskEvent.emit(taskId);
      }
      if (close) {
        this.clickedTask(null);
      }
    });
    this.subscriptions.push(closeTask);

    // Makes it so the statuses show up on init.
    this.refreshMilestoneStatus();
  }

  ngOnDestroy(): void {
    // attempt to close all active subscriptions
    try {
      for (const subscription of this.subscriptions) {
        subscription.unsubscribe();
      }
    } catch (e) {}
  }

  public get isProgressManuallySet(): boolean {
    if (this.milestoneData?.progress === 0) {
      return true;
    }

    if (this.milestoneData?.progress) {
      return true;
    }

    return false;
  }

  public get percentageOfTasksDone(): number {
    if (this.isProgressManuallySet) {
      return +this.milestoneData.progress;
    }

    // Dynamic calculation
    // check if its a valid number
    const totalTasks = this.tasks?.length;
    if (isNaN(totalTasks) || totalTasks === 0) {
      return 0;
    }

    // this will always be a number
    const doneTaskCount = this.tasks
      ?.filter((task) => task?.status_id && +task.status_id === TaskStatus.Complete)
      .reduce((count) => {
        return (count += 1);
      }, 0);

    return Math.round((doneTaskCount / totalTasks) * 100);
  }

  private async _rankHelper(event: CdkDragDrop<Milestone>) {
    // Clean up, ensure that every task has a rank
    const allTasksRanked = event.container.data?.tasks?.every((task: Task) => task.rank);
    if (allTasksRanked) {
      //  get the rank of the milestone, before and after the current one
      const beforeTaskRank = event.container?.data?.tasks[event.currentIndex - 1]?.rank;
      const afterTaskRank = event.container?.data?.tasks[event.currentIndex + 1]?.rank;
      const newRank = getRankBetween(beforeTaskRank, afterTaskRank);

      // update rank
      event.container.data.tasks[event.currentIndex].rank = newRank;

      // save it
      await this.taskService.updateTaskRank(event.container.data.tasks[event.currentIndex].id, newRank).toPromise();
    } else {
      // we handle it here
      const ranks = getDefaultRanks(event.container.data?.tasks?.length);
      event.container.data?.tasks?.forEach((task: Task, index: number) => (task.rank = ranks[index]));

      // then save them
      // we can save the ranks as well
      for (const task of event.container.data?.tasks ?? []) {
        await this.taskService.updateTaskRank(task.id, task.rank).toPromise();
      }
      this.isUpdatingTaskRank = false;
      this.isProcessingUpdatingTaskRank?.emit(this.isUpdatingTaskRank);
    }
  }

  public async dropTask(event: CdkDragDrop<Milestone>): Promise<void> {
    // drag and drop within milestone
    if (event.previousContainer === event.container) {
      // move item
      moveItemInArray(event.container.data.tasks, event.previousIndex, event.currentIndex);

      // rank
      this.isUpdatingTaskRank = true;
      this.isProcessingUpdatingTaskRank?.emit(this.isUpdatingTaskRank);
      await this._rankHelper(event);
      this.isUpdatingTaskRank = false;
      this.isProcessingUpdatingTaskRank?.emit(this.isUpdatingTaskRank);
    } else {
      // transfer
      transferArrayItem(
        event.previousContainer.data.tasks,
        event.container.data.tasks,
        event.previousIndex,
        event.currentIndex
      );

      // if the previous container has all tasks ranked, we good
      const allPreviousTasksRanked = event.previousContainer.data?.tasks?.every((task: Task) => task.rank);
      if (!allPreviousTasksRanked) {
        // we handle it here
        const ranks = getDefaultRanks(event.previousContainer.data?.tasks?.length);
        event.previousContainer.data?.tasks?.forEach((task: Task, index: number) => (task.rank = ranks[index]));

        // then save them
        this.isUpdatingTaskRank = true;
        this.isProcessingUpdatingTaskRank?.emit(this.isUpdatingTaskRank);
        // we can save the ranks as well
        for (const task of event.previousContainer.data?.tasks ?? []) {
          await this.taskService.updateTaskRank(task.id, task.rank).toPromise();
        }
        this.isUpdatingTaskRank = false;
        this.isProcessingUpdatingTaskRank?.emit(this.isUpdatingTaskRank);
      }

      // handle ranking the new task group
      this.isUpdatingTaskRank = true;
      this.isProcessingUpdatingTaskRank?.emit(this.isUpdatingTaskRank);
      await this._rankHelper(event);
      // update the task milestone_id
      await this.taskService
        .updateTaskMilestoneId(event.container.data.tasks[event.currentIndex].id, event.container.data.id)
        .toPromise();
      this.isUpdatingTaskRank = false;
      this.isProcessingUpdatingTaskRank?.emit(this.isUpdatingTaskRank);
    }
  }

  refreshMilestoneStatus() {
    this.milestoneStatus = this.calculateMilestoneStatus();
  }

  private taskNeedsToBeSeenOnInit(task: Task): boolean {
    if (task.status_id !== 3) {
      return true;
    }

    const daysUntilDue = this.daysUntilDate(task.due_date);
    return daysUntilDue <= 1;
  }

  private calculateMilestoneStatus() {
    if (this.tasks) {
      const statusCount = countBy(this.tasks, 'status_id');
      const taskCount = this.tasks.length;
      if (statusCount[1] === taskCount) {
        return MilestoneStatus.NotStarted;
      } else if (statusCount[3] === taskCount) {
        return MilestoneStatus.Complete;
      } else if (statusCount[2] === taskCount) {
        return MilestoneStatus.OnHold;
      } else if (statusCount[2]) {
        return MilestoneStatus.InProgressOnHold;
      } else {
        return MilestoneStatus.InProgress;
      }
    } else {
      return MilestoneStatus.NotStarted;
    }
  }

  async completeMilestone() {
    if (this.hasIncompleteReviews()) {
      // If there is a review task that is not finished the milestone cannot be completed
      this.modalService
        .openNotificationDialog({
          titleBarText: 'Unfinished Review Task',
          descriptionText: 'All reviews must be finished before this milestone can be completed',
          notificationButtonText: 'Ok',
        })
        .subscribe(async (confirmed) => {});
    } else {
      // Goes through each task creates a complete event, updates the task to complete then updates the ui.
      for (const task of this.tasks) {
        if (!task.hasOwnProperty('work_order_id') && task.status_id !== TaskStatus.Complete) {
          const newTask = await this.taskService.changeTaskStatus(task as Task, TaskStatus.Complete);
          const updatedTask = await this.projectService.updateTask(newTask).toPromise();
          await this.taskService.updateTask(updatedTask);
        }
      }
    }
  }

  deactivateMilestone() {
    this.modalService
      .openConfirmationDialog({
        titleBarText: 'Remove Milestone',
        descriptionText: 'Are you sure that you want to remove this Milestone?',
      })
      .subscribe(async (confirmed) => {
        if (confirmed) {
          if (!this.milestoneData.tasks || this.milestoneData.tasks.filter((t) => !t.can_delete).length === 0) {
            this.progressIndicatorService.openAwaitIndicatorModal();
            this.progressIndicatorService.updateStatus('Removing Milestone..');
            // TODO we really should deactivate all tasks in this milestone as well CPM-1579
            await this.projectService
              .deactivateMilestone(this.milestoneData.id)
              .toPromise()
              .then(() => {
                this.milestoneDeactivateEvent.emit(this.milestoneData);
                this.snackbar.open('Milestone Removed');
              });
            this.progressIndicatorService.close();
          } else {
            this.snackbar.open('Failed to Remove - Milestone contains tasks that cannot be deleted');
          }
        }
      });
  }

  openEditMilestoneDialog() {
    const dialogRef = this.dialog.open(MilestoneDialogComponent, {
      width: '420px',
      data: cloneDeep(this.milestoneData),
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.projectService.updateMilestone(result).subscribe((updatedMilestone) => {
          this.milestoneData.start_date = updatedMilestone.start_date;
          this.milestoneData.end_date = updatedMilestone.end_date;
          this.milestoneData.name = updatedMilestone.name;
          this.milestoneData.progress = updatedMilestone.progress;
          this.phaseUpdated.emit();
        });
        this.milestoneData.tasks = result.tasks;
        this.tasks = result.tasks;
      }
    });
  }

  // Checks to see if any of the tasks in the milestone are incomplete reviews.
  hasIncompleteReviews() {
    let hasIncompleteReview = false;
    this.tasks.forEach((task) => {
      if ('accessory_data' in task && task.accessory_data && task.status_id !== TaskStatus.Complete) {
        const accessoryData = JSON.parse(task.accessory_data);
        if (accessoryData.isReviewItem && accessoryData.reviewChain.length) {
          hasIncompleteReview = !!accessoryData.reviewChain.find(
            (reviewer) => reviewer.status !== TaskReviewStatus.Approved
          );
        }
      }
    });
    return hasIncompleteReview;
  }

  /** Toggle the expansion of the milestone view */
  toggleExpansion() {
    localStorage.setItem(
      'preferences',
      JSON.stringify(this.addToPreferences(this.milestoneData.id.toString(), this.isExpanded || undefined))
    );
    this.isExpanded = !this.isExpanded;
    this.milestoneData.is_closed = !this.isExpanded;
    if (!this.isExpanded) {
      this.isCreatingTask = false;
    }
  }

  get MileStoneStatus() {
    return MilestoneStatus;
  }

  get selectedTask(): Task | LinkedWOTask {
    return this.taskSelectedFromEmitter || this.taskService.currentSelectedTask;
  }

  public async clickedTask(task) {
    // if there is no project manager id and its passed through, then set it
    if (task && !task?.project_manager_id && this.projectManagerId) {
      task.project_manager_id = this.projectManagerId;
    }
    const isLinkedTask = task && 'work_order_id' in task;
    if (isLinkedTask) {
      this.progressIndicatorService.openAwaitIndicatorModal();
      this.progressIndicatorService.updateStatus('Loading task...');
      const linkedTask = await this.linkedTaskService.getLinkedWOTask(task.id).toPromise();
      this.progressIndicatorService.close();
      this.taskService.taskSelectedEvent.emit(null);
      this.taskSelectedFromEmitter = linkedTask;
      this.taskClicked.emit(linkedTask);
    } else {
      this.taskService.taskSelectedEvent.emit(task);
      this.taskSelectedFromEmitter = null;
      this.taskClicked.emit(task);
    }

    if (!task) {
      this.location.replaceState(`projects/${this.projectService.currentSelectedProjectId}/tasks`);
    }
  }

  public taskDeletedEvent(id: number) {
    const foundTask = this.milestoneData.tasks.find((t) => +t.id === +id);
    if (foundTask) {
      this.milestoneData.tasks.splice(this.milestoneData.tasks.indexOf(foundTask), 1);
    }
  }

  showCreateTaskComponent() {
    this.isExpanded = true;
    this.isCreatingTask = true;
    // this.taskService.taskSelectedEvent.emit(null);
  }

  public async createTask() {
    this.modalService
      .openCreateTaskModal({
        phaseName: this.milestoneData.phase_name,
        milestoneName: this.milestoneData.name,
      })
      .subscribe(async (task) => {
        if (task) {
          const freshTaskCopy = await this.projectService.getTaskById(task.id).toPromise();
          if (task.milestone_id === this.milestoneData.id) {
            if (!this.milestoneData.tasks) {
              this.milestoneData.tasks = [];
            }
            this.tasks.push(freshTaskCopy);
            // this.milestoneData.tasks.push(task);
          } else {
            await this.taskService.updateTask(freshTaskCopy);
          }
        }
      });
  }

  shouldShowCreateTaskComponent(): boolean {
    return this.isCreatingTask;
  }

  closeCreateTaskComponent() {
    this.isCreatingTask = false;
  }

  onTaskAdded(task: Task) {
    this.closeCreateTaskComponent();
    this.milestoneData.tasks.push(task);
    this.taskService.updateTask(task);
    this.taskService.selectTaskById(task.id, false).subscribe();
  }

  removeTaskComponent(taskId: number) {
    if (this.milestoneData && this.milestoneData.tasks) {
      const task = this.milestoneData.tasks.find((t) => +t.id === +taskId);
      if (task) {
        this.milestoneData.tasks.splice(this.milestoneData.tasks.indexOf(task), 1);
      }
    }
  }

  public onTaskConverted(data: { task: LinkedWOTask; oldTaskId: number }) {
    const { task, oldTaskId } = data;
    this.removeTaskComponent(oldTaskId);
    this.milestoneData.tasks.push(task);
  }

  private daysUntilDate(date) {
    return moment(date).startOf('day').diff(moment().startOf('day'), 'days');
  }

  public getPercentageOfTasksDone() {
    const totalTasks = this.tasks.length;
    if (isNaN(this.tasks.length)) {
      return 0;
    }

    const doneTaskCount = this.tasks
      ?.filter(({ status_id }) => +status_id === TaskStatus.Complete)
      .reduce((count, task) => {
        // TODO add a weighted value for override, now the default is 1
        return (count += 1);
      }, 0);
    return (doneTaskCount / totalTasks) * 100;
  }

  getTaskNumberLabelText() {
    const myTasks = this.tasks;
    return myTasks ? myTasks.length + (myTasks.length === 1 ? ' Task' : ' Tasks') : '0 Tasks';
  }

  // startConversation(message: string) {
  //   this.messageService.events.openCreateConversationPanelEvent.emit({
  //     subject: message,
  //     projectId: this.projectService.currentSelectedProject.id,
  //     followers: [],
  //   });
  // }

  createMeetingAgendaFromMilestone(milestone) {
    const meetingSelectDialogRef = this.dialog.open(MeetingSelectDialogComponent, {
      data: {
        title: 'Select Meeting for New Agenda Item',
        parent_type_id: ResourceType.Milestone,
        parent_id: milestone.id,
      },
    });

    meetingSelectDialogRef.afterClosed().subscribe((returnedMeeting) => {
      if (returnedMeeting) {
        const agendaDialogRef = this.dialog.open(AgendaItemDialogComponent, {
          width: '480px',
          data: {
            meeting_id: returnedMeeting.id,
            meeting_title: returnedMeeting.title,
            meeting_code: returnedMeeting.code,
          },
        });

        agendaDialogRef.afterClosed().subscribe((agendaItem) => {
          if (agendaItem) {
            const agendaItemToAdd = {
              meeting_id: returnedMeeting.id,
              description: agendaItem.description,
              duration: agendaItem.duration,
              parent_type_id: ResourceType.Milestone,
              parent_id: milestone.id,
            };
            this.meetingService.addAgendaItem(agendaItemToAdd).subscribe((newAgendaItem) => {
              this.snackbar.open('Agenda item added!');
            });
          }
        });
      }
    });
  }

  get userIsStaff(): boolean {
    return this.authService.isUserWorkspaceStaff(this.projectService.currentSelectedProject?.module_id);
  }

  get isProjectAdmin() {
    return this.authService.isProjectAdmin(
      this.projectService.currentSelectedProjectId,
      this.projectService.currentSelectedProject.module_id
    );
  }

  private addToPreferences(key: string, value: any) {
    const preferences = JSON.parse(localStorage.getItem('preferences')) || {};
    const closedMilestones = (preferences && preferences.closed_milestones) || {};

    closedMilestones[key] = value;
    preferences.closed_milestones = closedMilestones;

    return preferences;
  }
}
