<app-base-dialog [title]="dialogTitle" (closeDialog)="close()"></app-base-dialog>
<div class="project-modal work-order-dialog p-2" id="work-order-dialog" data-test-id="work-order-dialog">
  <form [formGroup]="workOrderFormGroup">
    <div class="row">
      <div class="col-6">
        <div class="row mb-3 align-items-center">
          <div class="col-12">
            <h6 class="m-0 dkblue font-weight-semibold mb-2">Requested By*</h6>
            <app-search-user-input
              #requested_by
              [userTypes]="notVendors"
              [placeholder]="requestorPlaceholder"
              [show_department]="true"
              [initialValueId]="initialRequestingUserId"
            ></app-search-user-input>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <app-request-method #requestMethod></app-request-method>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-12">
            <app-work-order-topic
              #workOrderTopic
              [workOrder]="work_order_data"
              [module_id]="module_id"
              [allowEditingWorkspace]="allowEditingWorkspace"
              (topicChanged)="topicChanged($event)"
            ></app-work-order-topic>
          </div>
        </div>
        <div class="row mb-4 align-items-center">
          <h6 class="col-12 m-0 dkblue font-weight-semibold mb-2">Assigned To</h6>
          <div class="col-12">
            <app-search-user-input
              #assigned_to
              [userTypes]="notVendors"
              [placeholder]="assigneePlaceholder"
              [show_department]="true"
              [disabled]="isEditing"
              [initialValueId]="initialAssignedUserId"
            ></app-search-user-input>
          </div>
        </div>
        <div class="row mb-4 align-items-center">
          <h6 class="col-12 m-0 dkblue font-weight-semibold mb-2">CMS WO#</h6>
          <div class="col-12">
            <mat-form-field class="full-width" appearance="outline">
              <input
                placeholder="CMS WO#"
                id="work-order-landmark-input"
                data-test-id="work-order-landmark-input"
                matInput
                formControlName="cms_work_order_id"
              />
              <mat-error *ngIf="cms_work_order_id?.errors?.pattern">8 digits or less</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mb-4 align-items-center">
          <div class="col">
            <h6 class="m-0 dkblue font-weight-semibold mb-2">
              <span class="mr-2"> Priority Level: </span>
              <span
                [ngClass]="{
                  gray: !priorityId,
                  dkgray: priorityId === 1,
                  ltblue: priorityId === 2,
                  red: priorityId === 3,
                  dkred: priorityId === 4
                }"
              >
                {{ priorityName }}
              </span>
            </h6>
            <mat-button-toggle-group
              class="full-width"
              type="button"
              name="priority"
              aria-label="Priority"
              formControlName="priority"
            >
              <mat-button-toggle
                style="width: 20%"
                type="button"
                *ngFor="let priorityOption of workOrderPriorities"
                [ngClass]="{
                  gray: !priorityOption?.id,
                  dkgray: priorityOption?.id === 1,
                  ltblue: priorityOption?.id === 2,
                  red: priorityOption?.id === 3,
                  dkred: priorityOption?.id === 4
                }"
                class="font-weight-semibold"
                [value]="priorityOption"
                >{{ priorityOption.name }}</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row mb-4">
          <div class="col">
            <h6 class="m-0 dkblue font-weight-semibold mb-2">Short Description*</h6>
            <mat-form-field class="full-width" appearance="outline">
              <input
                placeholder="Short Description"
                id="work-order-titleinput"
                data-test-id="work-order-title-input"
                matInput
                required="true"
                type="text"
                formControlName="title"
              />
              <mat-error *ngIf="title?.errors?.required">Short Description is required</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col textarea">
            <h6 class="dkblue mb-2">Summary*</h6>
            <app-editor
              #editor
              placeholder="Summary"
              required_content="Work Order Summary is Required"
              required="true"
            ></app-editor>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <app-location #locations [buildingIds]="buildingIds"></app-location>
          </div>
        </div>
        <div class="row mt-0 mb-3">
          <div class="col">
            <h6 class="m-0 dkblue font-weight-semibold mb-2">Room # / Landmark</h6>
            <mat-form-field class="full-width" appearance="outline">
              <input
                placeholder="Room # / Landmark"
                id="work-order-landmark-input"
                data-test-id="work-order-landmark-input"
                matInput
                type="text"
                formControlName="landmark"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <h6 class="font-weight-semibold dkblue mb-1">Due Date</h6>
            <mat-form-field appearance="outline" class="full-width">
              <input matInput placeholder="MM/DD/YYYY" [matDatepicker]="picker1" formControlName="due_date" />
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1 [calendarHeaderComponent]="customHeader"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h6 class="font-weight-semibold dkblue mb-2">Tags</h6>
            <div class="row mb-4">
              <mat-checkbox
                *ngFor="let tag of selectedTags?.controls || []; let i = index"
                class="col-6 py-2"
                [formControl]="tag"
                (ngModelChange)="changeTagData($event, tags[i]?.id)"
              >
                <span class="font-weight-semibold dkblue mt-2">
                  {{ tags[i]?.name }}
                  {{ tags[i]?.abbreviation !== tags[i]?.name ? '(' + tags[i]?.abbreviation + ')' : '' }}</span
                >
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-chip-list class="d-inline-block align-middle">
          <button (click)="openAttachFileDialog()" mat-button type="button" color="accent" class="lh-24 pl-1 pr-2">
            <mat-icon
              matRipple
              color="accent"
              class="pointer"
              matTooltip="Attach Files"
              matTooltipPosition="above"
              [matTooltipShowDelay]="300"
              style="font-size: 20px; height: 20px"
              >attach_file</mat-icon
            >
            <span class="ml-1">Attach Files</span>
          </button>
          <app-file-chip
            *ngFor="let file of attachedWorkOrderFiles"
            [file]="file"
            [removable]="true"
            (removeEvent)="removeAttachedFile(file)"
          ></app-file-chip>
          <app-file-chip
            *ngFor="let file of linkedProjectFiles"
            [file]="file"
            [removable]="true"
            (removeEvent)="removeLinkedProjectFile(file)"
          ></app-file-chip>
        </mat-chip-list>
      </div>
    </div>
    <div class="row mb-2" style="margin-top: 0.5rem">
      <div class="col text-right">
        <button data-test-id="create-task-dialog-cancel-button" (click)="close()" mat-button type="button" class="gray">
          Cancel
        </button>
        <button mat-flat-button type="button" color="success" class="ml-3" (click)="submit()">Save Work Order</button>
      </div>
    </div>
  </form>
</div>
