/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { isEmpty } from 'lodash';
// import { remove } from 'lodash';
import { ModalService, SearchService, SidenavService, UserService } from 'src/app/services';
import { Invoice } from 'src/app/types';

interface Section {
  name: string;
  label: string;
  link?: string;
  link_properties?: string[];
  hidden?: boolean;
}
@Component({
  selector: 'app-search-overlay-panel',
  templateUrl: './search-overlay-panel.component.html',
  styleUrls: ['./search-overlay-panel.component.scss'],
})
export class SearchOverlayPanelComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput', { static: true }) searchInput;

  constructor(
    private modalService: ModalService,
    private router: Router,
    private sidenavService: SidenavService,
    private searchService: SearchService,
    private userService: UserService,
    private snackbar: MatSnackBar
  ) {}

  sections: Section[] = [
    {
      name: 'arfs',
      label: 'ARFs',
      link: '/purchasing/arfs/link_property',
      link_properties: ['id'],
    },
    {
      name: 'invoices',
      label: 'Invoices',
      link: '/projects/link_property/invoices',
      link_properties: ['project_id'],
    },
    {
      name: 'workorders',
      label: 'Work Orders',
      link: '/work-orders/link_property',
      link_properties: ['id'],
    },
    {
      name: 'workorderupdates',
      label: 'Work Order Updates',
      link: '/work-orders/link_property',
      link_properties: ['work_order_id'],
    },
    {
      name: 'requests',
      label: 'Requests',
      link: '/requests/link_property',
      link_properties: ['id'],
    },
    {
      name: 'projects',
      label: 'Projects',
      link: '/projects/link_property',
      link_properties: ['id'],
    },
    {
      name: 'projectupdates',
      label: 'Project Updates',
      link: '/projects/link_property',
      link_properties: ['project_id'],
    },
    { name: 'tasks', label: 'Tasks', link: '/tasks/link_property', link_properties: ['id'] },
    {
      name: 'tasknotes',
      label: 'Task Notes',
      link: '/tasks/link_property',
      link_properties: ['parent_id'],
    },
    { name: 'users', label: 'Users', link: '/profile/link_property', link_properties: ['id'] },
    {
      name: 'meetings',
      label: 'Meetings',
      link: '/meetings/link_property',
      link_properties: ['id'],
    },
    // {
    //   name: 'agendas',
    //   label: 'Meeting Agendas',
    //   link: '/meetings/link_property',
    //   link_properties: ['meeting_id'],
    // },
    {
      name: 'agendanotes',
      label: 'Agenda Notes',
      link: '/meetings/link_property',
      link_properties: ['meeting_id'],
    },
    { name: 'files', label: 'Files' }, // TODO: link
    // { name: 'messages', label: 'Messages' },
    // { name: 'conversations', label: 'Conversations' },
    // {
    //   name: 'bids',
    //   label: 'Bids',
    //   link: 'projects/link_property/bids/',
    //   link_properties: ['project_id'],
    // },
    // {
    //   name: 'change_orders',
    //   label: 'COs',
    //   link: 'projects/link_property/change-orders/',
    //   link_properties: ['project_id'],
    // },
    // {
    //   name: 'milestones',
    //   label: 'Milestones',
    //   link: '/projects/link_property/tasks',
    //   link_properties: ['project_id'],
    // }, // TODO: link better
    // {
    //   name: 'proposal_requests',
    //   label: 'PRs',
    //   link: 'projects/link_property/proposal-requests/',
    //   link_properties: ['project_id'],
    // }, // TODO
    // {
    //   name: 'punchlists',
    //   label: 'Punchlists',
    //   link: 'projects/link_property/punchlist/',
    //   link_properties: ['project_id'],
    // }, // TODO
    // { name: 'reminders', label: 'Reminders', link: '/reminders/', link_properties: null },
    // { name: 'rfi_comments', label: 'RFI Comments' },
    // {
    //   name: 'rfis',
    //   label: 'RFIs',
    //   link: 'projects/link_property/rfi/',
    //   link_properties: ['project_id'],
    // },
  ];
  filteredSections = [];

  results = {};
  searchPerformed = false;
  hasResults = false;
  loaders = {
    search: false,
  };

  searchTerm: string;
  lastSearchTerm: string;
  // searchProject;

  panelToggleSubscription;

  private sectionMap = new Map(); // will have the loaded sections on init

  ngOnInit() {
    this.sections.forEach((section) => (section.hidden = true));

    // fill the map with the retrieved sections
    for (const section of this.sections) {
      this.sectionMap.set(section.name, section);
    }

    this.panelToggleSubscription = this.searchService.searchPanelToggled.subscribe(() => {
      if (this.searchService.searchPanelIsOpen) {
        this.focusSearchInput();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.panelToggleSubscription) {
      this.panelToggleSubscription.unsubscribe();
    }
  }

  public closeSearchPanel() {
    this.searchService.searchPanelIsOpen = false;
  }

  public isPanelOpen() {
    return this.searchService.searchPanelIsOpen;
  }

  public isSidenavOpen() {
    return !this.sidenavService.isSidenavClosed;
  }

  public async search(): Promise<void> {
    const searchTerm = this.searchTerm?.trim();
    if (searchTerm.length < 3) {
      this.snackbar.open(`Your search term must be at least three letters long`);
    } else {
      this.focusSearchInput();
      this.loaders.search = true;
      try {
        this.clearResultPanel();
        const searchResults = await this.searchService.search(searchTerm).toPromise();
        this.hasResults = !isEmpty(searchResults);
        if (this.hasResults) {
          for (const [sectionName, sectionValues] of Object.entries(searchResults)) {
            const section: Section = this.sectionMap?.get(sectionName);
            // loop through the results and create each result link
            for (const result of sectionValues as Record<string, string>[]) {
              result.link = this._createLink(section, result);
            }
          }
        }
        this.results = searchResults;
        this.lastSearchTerm = searchTerm;
        this.searchPerformed = true;
        this.loaders.search = false;
      } catch (e) {
        this.loaders.search = false;
        throw e;
      }
    }
  }

  clearResultPanel() {
    this.results = {};
    this.hasResults = false;
    this.searchPerformed = false;
  }

  focusSearchInput() {
    this.searchInput.nativeElement.focus();
  }

  clearSearch() {
    this.searchTerm = null;
    this.clearResultPanel();
  }

  private _createLink(section: Section, result): string {
    let link: string = section.link;
    if (section.name === 'invoices' && result?.bid_package?.project_id) {
      link = link.replace('link_property', result?.bid_package?.project_id);
    } else if (section.name === 'invoices' && result?.arf?.id) {
      link = `/purchasing/arfs/${result.arf.id}`;
    } else if (section.name === 'invoices' && result?.quote?.project_id) {
      link = link.replace('link_property', result?.quote.project_id);
    } else if (section.name == 'tasks' && result?.arf?.id) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      link = `/purchasing/arfs/${result?.arf.id}`;
    } else if (link && section.link_properties) {
      for (const p of section.link_properties) {
        // Replace first instance of 'link_property' with result[p]. This requires link_properties to be in order.
        link = link.replace('link_property', result[p]);
      }
    } else if (section.name === 'files') {
      return this.getFileLink(result);
    }

    // return formed link
    return link || '#';
  }

  async gotoResultLink(section, result) {
    let link: string = section.link;
    if (section.name == 'tasks' && result.arf?.id) {
      link = `/purchasing/arfs/${result.arf.id}`;
    } else if (link && section.link_properties) {
      for (const p of section.link_properties) {
        // Replace first instance of 'link_property' with result[p]. This requires link_properties to be in order.
        link = link.replace('link_property', result[p]);
      }
    } else if (section.name === 'files') {
      link = this.getFileLink(result);
    }
    this.searchService.logClickthrough(this.searchTerm, result.id, section.name, link).subscribe();
    if (link) {
      await this.router.navigate([link]);
    } else {
      this.snackbar.open('Sorry, there is no valid link for this item.');
    }
  }

  // TODO grab parents of file to get a valid link
  public getFileLink(result: {
    parent_type_id: number;
    parent_id: number;
    meeting_id: number;
    project_id: number;
    work_order_id: number;
  }): string {
    let link: string;
    switch (result.parent_type_id) {
      case 3:
        link = `/projects/${result.parent_id}/files`;
        break;
      case 7:
        link = `/tasks/${result.parent_id}`;
        break;
      case 8:
        link = `/requests/${result.parent_id}`;
        break;
      case 11:
        link = `/meetings/${result.meeting_id}`;
        break;
      case 15:
        link = `/projects/${result.project_id}/bids`;
        break;
      case 17:
        link = `/projects/${result.project_id}/proposal-requests`;
        break;
      case 32:
        link = `/projects/${result.project_id}/overview`;
        break;
      case 34:
        link = `/work-orders/${result.parent_id}`;
        break;
      case 40:
        link = `/work-orders/${result.work_order_id}`;
        break;
      default:
        break;
    }
    return link;
  }

  public getProfileThumbnailUrl(userId: number): string {
    return this.userService.getProfileThumbnailUrl(userId);
  }

  // addFilter(section) {
  //   const foundFilter = this.filteredSections.find((f) => f.name === section.name);
  //   if (!foundFilter) {
  //     this.filteredSections.push(section);
  //   }
  // }
  //
  // removeFilter(section) {
  //   remove(this.filteredSections, (f) => f.name === section.name);
  // }

  public pipeJoin(arr: string[]): string {
    return arr.reduce((a, c) => (a ? (c ? a + ' | ' + c : a) : c), '');
  }

  public viewInvoice(invoice: Invoice): void {
    this.modalService.openViewInvoiceModal(invoice?.id, invoice?.module_id);
  }

  reloadComponent(self: boolean, urlToNavigateTo?: string) {
    const url = self ? this.router.url : urlToNavigateTo;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/${url}`]).then(() => {
        // console.log(`Component Reloaded!`);
      });
    });
  }
}
