import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import {
  DatepickerHeaderComponent,
  EditorComponent,
  FileAttachmentDialogComponent,
  LocationComponent,
  RequestMethodComponent,
  SearchUserInputComponent,
  WorkOrderTopicComponent,
} from 'src/app/components';
import { ResourceTags, ResourceType, UserType } from 'src/app/enums';
import {
  AuthService,
  FileService,
  LinkedTaskService,
  ModuleService,
  ProgressIndicatorService,
  ProjectService,
  ResourceTagsService,
  WorkOrderService,
} from 'src/app/services';
import {
  APIFilter,
  Building,
  Department,
  FileAttachmentDialogInjectionData,
  Floor,
  LinkedWOTask,
  RequestMethod,
  ResourceTag,
  Suite,
  UhatFileReference,
  WorkOrder,
  WorkOrderPriority,
} from 'src/app/types';
import { CustomValidator } from 'src/app/utils';
import { UhatFileBridgeLink } from '../../models';

const NOT_SET_PRIORITY: WorkOrderPriority = { id: null, name: 'Not Set', abbreviation: 'None' };

@Component({
  selector: 'app-work-order-dialog',
  templateUrl: './work-order-dialog.component.html',
  styleUrls: ['./work-order-dialog.component.scss'],
})
export class WorkOrderDialogComponent implements OnInit {
  @ViewChild('editor', { static: true }) private _editor_component: EditorComponent;
  @ViewChild('locations', { static: true }) private _location_component: LocationComponent;
  @ViewChild('requested_by', { static: true })
  private _requested_by_component: SearchUserInputComponent;
  @ViewChild('requestMethod', { static: true })
  private _requestMethodComponent: RequestMethodComponent;
  @ViewChild('assigned_to', { static: true })
  private _assigned_to_component: SearchUserInputComponent;
  @ViewChild('workOrderTopic', { static: true })
  private _work_order_topic_component: WorkOrderTopicComponent;

  customHeader = DatepickerHeaderComponent;

  public buildings: Building[] = [];
  public buildingIds: number[];
  public departments: Department[] = [];
  public dialogTitle = 'New Work Order';
  public floors: Floor[] = [];
  public isEditing = false;
  public isLoading = false;
  public notVendors = [UserType.Staff, UserType.Tenant];
  public requestorPlaceholder = 'Requestor';
  public assigneePlaceholder = 'Assignee';
  public submitMessage = 'Creating work order...';
  public suites: Suite[] = [];
  public tags: ResourceTag[] = [];
  public workOrderTags: number[] = [];
  public workOrderFormGroup: FormGroup = this._fb.group({
    due_date: [''],
    tags: new FormArray([]),
    priority: [NOT_SET_PRIORITY],
    title: ['', [Validators.required]],
    landmark: [''],
    cms_work_order_id: ['', [Validators.pattern('^[0-9]{1,8}$')]],
  });
  public workOrderPriorities: WorkOrderPriority[] = [NOT_SET_PRIORITY];
  public allowEditingWorkspace = false;
  public initialAssignedUserId = null;
  public initialRequestingUserId = null;
  public attachedWorkOrderFiles: UhatFileReference[] = [];
  public linkedProjectFiles: UhatFileReference[] = [];
  private linkedWorkOrderTask?: LinkedWOTask;

  constructor(
    @Inject(MAT_DIALOG_DATA) public work_order_data,
    private _authService: AuthService,
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<WorkOrderDialogComponent>,
    private _fb: FormBuilder,
    private _fileService: FileService,
    private _moduleService: ModuleService,
    private _progressIndicatorService: ProgressIndicatorService,
    private resourceTagsService: ResourceTagsService,
    private _workOrderService: WorkOrderService,
    private projectService: ProjectService,
    private linkedTaskService: LinkedTaskService
  ) {}

  async ngOnInit() {
    this._openProgress('Loading...');
    if (this.work_order_data?.id) {
      // don't edit work space if editing work order
      this.allowEditingWorkspace = false;
    } else if (this.work_order_data?.allowEditingWorkspace) {
      this.allowEditingWorkspace = true;
    }
    const tagFilter: APIFilter[] = [
      { type: 'field', field: `resource_type_ids`, value: ResourceType.WorkOrder, match: 'exact' },
      { type: 'operator', value: 'AND' },
      { type: 'field', field: 'is_enabled', value: 1 },
    ];

    if (this.work_order_data?.tags?.length) {
      const disabledTags =
        this.work_order_data?.tags?.filter(
          (tag) =>
            !Number(tag.is_enabled) || !JSON.parse(tag.resource_type_ids.toString()).includes(ResourceType.WorkOrder)
        ) || [];

      tagFilter.push({ type: 'operator', value: 'OR' });
      tagFilter.push({ type: 'field', field: 'id', value: disabledTags.map((t) => t.id).join('^') });
    }

    this.tags = await this.resourceTagsService.getResourceTags(tagFilter).toPromise();

    await this._activateLocationFields();
    await this._activateRequestedByField();
    // add rm to the form
    this.workOrderFormGroup.addControl('request_method', this._requestMethodComponent?.requestMethod);
    this._requestMethodComponent.ready.subscribe(async () => await this._populateSelectedRequestMethod());
    await this._activateAssignedToField();
    await this._activateSummaryField();
    await this._getWorkOrderPriorities();
    await this._activateTopicFields();
    await this._populateDialog();

    // add/remove CoMa tag when cms value is added/removed
    this.cms_work_order_id.valueChanges.subscribe((value) => {
      const comaTagIndex = this.tags.findIndex((t) => t.id == ResourceTags.CoMa);
      const tag = this.selectedTags.controls[comaTagIndex];
      if (!tag) {
        return;
      }
      if (value) {
        tag.setValue(true);
        tag.disable();
      } else {
        tag.setValue(false);
        tag.enable();
      }
    });
    this.cms_work_order_id.updateValueAndValidity();

    if (!this.projectService.currentSelectedProject?.id && this.work_order_data?.id) {
      this.linkedWorkOrderTask = await this.linkedTaskService
        .getLinkedWOTaskForWorkOrder(this.work_order_data?.id)
        .toPromise();
    }

    this._closeProgress();
  }

  get building(): AbstractControl {
    return this.location.get('building');
  }

  get department(): AbstractControl {
    return this.location.get('department');
  }

  get dispatch(): boolean {
    return this.workspace.name === 'Dispatch';
  }

  get due_date(): AbstractControl {
    return this.workOrderFormGroup.get('due_date');
  }

  get floor(): AbstractControl {
    return this.location.get('floor');
  }

  get selectedTags() {
    return this.workOrderFormGroup.controls.tags as FormArray;
  }
  get landmark(): AbstractControl {
    return this.workOrderFormGroup.get('landmark');
  }

  get location(): AbstractControl {
    return this.workOrderFormGroup.get('location');
  }

  // coming from the topic component
  get module(): AbstractControl {
    return this.topicForm?.get('workspace');
  }
  get priority(): AbstractControl {
    return this.workOrderFormGroup.get('priority');
  }

  get priorityAbbreviation(): number {
    return this.priority?.value?.abbreviation;
  }

  get priorityId(): number {
    return this.priority?.value?.id;
  }

  get priorityName(): number {
    return this.priority?.value?.name;
  }

  get requested_by(): AbstractControl {
    return this.workOrderFormGroup.get('requested_by');
  }

  get assigned_to(): AbstractControl {
    return this.workOrderFormGroup.get('assigned_to');
  }

  get requesterId(): number {
    return this.requested_by?.value?.id;
  }

  get requestMethod(): AbstractControl {
    return this.workOrderFormGroup.get('request_method');
  }

  get assignedUserId(): number {
    return this.assigned_to?.value?.id;
  }

  get suite(): AbstractControl {
    return this.location.get('suite');
  }

  get summary(): AbstractControl {
    return this.workOrderFormGroup?.get('summary');
  }

  get title(): AbstractControl {
    return this.workOrderFormGroup?.get('title');
  }

  get topicForm(): AbstractControl {
    return this.workOrderFormGroup?.get('topicForm');
  }

  get topic_categories(): AbstractControl {
    return this.topicForm?.get('topic_category');
  }

  get topic_groups(): AbstractControl {
    return this.topicForm?.get('topic_group');
  }

  get topics(): AbstractControl {
    return this.topicForm?.get('topic');
  }

  get valid_due_date(): string | Date {
    return moment(this.due_date.value).isValid() ? moment(this.due_date.value).format('YYYY-MM-DD HH:mm:ss') : null;
  }

  get validForSubmission(): boolean {
    return !this.workOrderFormGroup.pristine && this.workOrderFormGroup.valid && !!this?.requesterId;
  }

  get workspace() {
    return this._moduleService.workspace;
  }

  get workspaces() {
    return this._moduleService.allWorkspaces;
  }

  get cms_work_order_id() {
    return this.workOrderFormGroup.get('cms_work_order_id');
  }

  private async _activateLocationFields() {
    this.workOrderFormGroup.addControl('location', this._location_component.locationGroup);
  }

  private async _activateRequestedByField() {
    this.workOrderFormGroup.addControl('requested_by', this._requested_by_component.searchEntry);
    this.requested_by.setValidators([Validators.required, CustomValidator.userId]);
    this.requested_by.updateValueAndValidity();
  }

  private async _activateAssignedToField() {
    this.workOrderFormGroup.addControl('assigned_to', this._assigned_to_component.searchEntry);
    this.assigned_to.setValidators([CustomValidator.userId]);
    this.assigned_to.updateValueAndValidity();
  }

  private async _activateSummaryField() {
    this.workOrderFormGroup.addControl('summary', this._editor_component.content);
  }

  private async _activateTopicFields() {
    this.workOrderFormGroup.addControl('topicForm', this._work_order_topic_component.topicFormGroup);
  }

  private _closeProgress() {
    this._progressIndicatorService.close();
    this.isLoading = false;
  }

  private async _getWorkOrderPriorities() {
    const workOrderPriorities: WorkOrderPriority[] = await this._workOrderService
      .getWorkOrderPriorities(['id', 'name', 'abbreviation'])
      .toPromise();

    this.workOrderPriorities = [NOT_SET_PRIORITY, ...workOrderPriorities];
  }

  private _openProgress(action: string) {
    this.isLoading = true;
    this._progressIndicatorService.openAwaitIndicatorModal();
    this._progressIndicatorService.updateStatus(action);
  }

  private async _populateDialog() {
    if (!this.work_order_data) {
      this.requested_by.setValue(this._authService.currentUser);
    } else {
      // only enter if there is data
      this.isEditing = true;
      if (this.work_order_data.id) {
        this.dialogTitle = 'Edit Work Order';
        this.submitMessage = 'Updating work order...';
      } else if (this.work_order_data) {
        // converting to work order
        this.submitMessage = 'Converting To work order...';
      } else {
        // new work order
      }

      // requester
      await this._populateRequestedBy();

      // request method
      this._populateSelectedRequestMethod();

      // assignee
      await this._populateAssignedTo();
      // Location
      // Set topic group buildingIds which will trigger a reload of available buildings in the dropdown menu
      if (this.work_order_data.topic_group) {
        const topicBuildingIds = JSON.parse(this.work_order_data.topic_group.building_ids);
        if (topicBuildingIds !== null && !topicBuildingIds.find((v) => v === this.work_order_data.building.id)) {
          topicBuildingIds.push(this.work_order_data.building.id);
        }
        this.buildingIds = topicBuildingIds;
      }
      // populate location data after building dropdown so that it will not be cleared
      await this._location_component.populateLocation(this.work_order_data);
      // priority
      await this._populatePriority();

      // fill out the form with data
      this.workOrderFormGroup.patchValue({
        due_date: this.work_order_data?.due_date,
        summary: this.work_order_data?.summary || '',
        title: this.work_order_data?.title || '',
        landmark: this.work_order_data?.landmark || '',
        cms_work_order_id: this.work_order_data?.cms_work_order_id || '',
      });
    }

    this.workOrderTags = (this.work_order_data?.tag_ids && JSON.parse(this.work_order_data.tag_ids)) || [];
    if (this.selectedTags.value?.length) {
      const values = [];
      this.tags.forEach((tag) => values.push(this.workOrderTags.includes(tag.id)));
      this.selectedTags.setValue(values);
    } else {
      this.tags.forEach((tag) => this.selectedTags.push(new FormControl(this.workOrderTags.includes(tag?.id))));
    }
  }

  private async _populatePriority() {
    if (this.work_order_data?.priority_id) {
      this.priority.setValue(
        this.workOrderPriorities.find((priority) => priority.id === this.work_order_data?.priority?.id)
      );
    } else {
      this.priority.setValue(NOT_SET_PRIORITY);
    }
  }

  private async _populateRequestedBy() {
    if (this.work_order_data?.requester) {
      this.requested_by.setValue(this.work_order_data?.requester);
      this.initialRequestingUserId = this.work_order_data.requester_id;
    }
  }

  private async _populateSelectedRequestMethod() {
    // filter disabled except our id
    this._requestMethodComponent.shownRequestMethods = this._requestMethodComponent.requestMethods.filter(
      (requestMethod: RequestMethod) =>
        requestMethod.is_enabled ||
        (+this.work_order_data?.request_method_id && +requestMethod?.id === +this.work_order_data.request_method_id)
    );
    if (this.work_order_data?.request_method_id) {
      const foundRequestMethod = this._requestMethodComponent.shownRequestMethods.find(
        (requestMethod: RequestMethod) => +requestMethod.id === +this.work_order_data.request_method_id
      );
      this.requestMethod.setValue(foundRequestMethod);
    } else {
      this.requestMethod.setValue(this._requestMethodComponent?.defaultRequestMethod);
    }
    this.requestMethod.updateValueAndValidity();
  }

  private async _populateAssignedTo() {
    if (this.work_order_data?.assigned_user) {
      this.assigned_to.setValue(this.work_order_data.assigned_user);
      this.initialAssignedUserId = this.work_order_data.assigned_user_id;
    }
  }

  public topicChanged(topicChange) {
    // if topic group changed reset buidlings list
    if (topicChange.changeLevel === 'group') {
      if (topicChange.buildingIds) {
        const topicBuildingIds = JSON.parse(topicChange.buildingIds);
        if (this.work_order_data?.building && !topicBuildingIds.find((t) => t === this.work_order_data.building?.id)) {
          topicBuildingIds.push(this.work_order_data.building.id);
        }
        this.buildingIds = topicBuildingIds;
      } else {
        this.buildingIds = null;
      }
    }

    // check to see if new topic has a priority.  If it is higher than what is selected raise priority.
    if (topicChange.changeLevel === 'topic') {
      if (!topicChange.priorityId && !this.priorityId) {
        this.priority.setValue(NOT_SET_PRIORITY);
      } else if (topicChange.priorityId && topicChange.priorityId > this.priorityId) {
        this.priority.setValue(this.workOrderPriorities.find((priority) => priority.id === topicChange.priorityId));
      }
    }
  }

  changeTagData(event, tagId) {
    this.workOrderTags = event ? [...this.workOrderTags, tagId] : this.workOrderTags.filter((id) => id !== tagId);
  }

  public get module_id(): number {
    return this.work_order_data?.module_id || this._moduleService.workspace_id;
  }

  public close(): void {
    this.isLoading = false;
    this._dialogRef.close();
  }

  public async submit() {
    // programmatically touch the fields that need touching
    this.workOrderFormGroup.markAllAsTouched();

    if (this.workOrderFormGroup.valid) {
      const idsToAdd = [];
      this.selectedTags?.value?.forEach((status, index) => {
        const currentTag = this.tags[index];
        if (status) {
          idsToAdd.push(currentTag.id);
        }
      });

      this._openProgress(this.submitMessage);
      const workOrderDetails: WorkOrder = {
        assigned_user_id: this.assignedUserId ?? null,
        building_id: this.building?.value?.id || null,
        department_id: this?.department?.value?.id || null,
        due_date: this.valid_due_date,
        tag_ids: JSON.stringify(idsToAdd),
        floor_id: this?.floor?.value?.id || null,
        module_id: this.module?.value?.id || this.workspace?.id || null,
        priority_id: this.priorityId || null,
        suite_id: this?.suite?.value?.id || null,
        summary: this.summary.value || null,
        title: this.title.value || null,
        topic_id: this.topics?.value?.id || null,
        landmark: this.landmark.value || null,
        request_method_id: this.requestMethod?.value?.id || null,
        requester_id: this.requesterId,
        cms_work_order_id: this.cms_work_order_id?.value || null,
      };

      if (this.work_order_data?.follower_ids) {
        workOrderDetails.follower_ids = this.work_order_data?.follower_ids;
      }

      // if it has id edit, otherwise, create
      const results: WorkOrder = await (this.work_order_data?.id
        ? this._workOrderService.updateWorkOrder(this.work_order_data?.id, workOrderDetails).toPromise()
        : this._workOrderService.createWorkOrder(workOrderDetails).toPromise());

      // if the files, save them
      if (results?.id && this.attachedWorkOrderFiles?.length) {
        for (const file of this.attachedWorkOrderFiles) {
          await this._fileService
            .createFile(file, results?.id, ResourceType.WorkOrder)
            .toPromise()
            .then(async (createdFile: UhatFileReference) => {
              await this._fileService.linkFile(createdFile.id, results.id, ResourceType.WorkOrder).toPromise();
            });
        }
      }

      // if project files, link them
      if (results?.id && this.linkedProjectFiles?.length) {
        for (const file of this.linkedProjectFiles) {
          await this._fileService.linkFile(file.file_id, results?.id, ResourceType.WorkOrder).toPromise();
        }
      }

      this.isLoading = false;
      this._closeProgress();
      this._dialogRef.close(results);
    }
  }

  public openAttachFileDialog(): void {
    const data: FileAttachmentDialogInjectionData = {
      parentResourceType: ResourceType.WorkOrder,
      parentResourceId: null,
      preSelectedTags: [],
      allowComment: false,
      workOrderFile: true,
      currentAttachedFiles: this.attachedWorkOrderFiles,
      skipUpload: true,
    };

    if (this.work_order_data?.id) {
      data.parentResourceType = ResourceType.WorkOrder;
      data.parentResourceId = this.work_order_data.id;
    }

    if (this.projectService.currentSelectedProject?.id ?? this.linkedWorkOrderTask?.project_id) {
      data.additionalParents = [
        new UhatFileBridgeLink(
          this.projectService.currentSelectedProject?.id ?? this.linkedWorkOrderTask?.project_id,
          ResourceType.Project
        ),
      ];
      data.workOrderFile = false;
    }

    this._dialog
      .open(FileAttachmentDialogComponent, {
        data,
        disableClose: true,
      })
      .afterClosed()
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      .subscribe((files: FileAttachmentDialogInjectionData) => {
        if (files?.computerFiles?.length) {
          this.attachedWorkOrderFiles = [...this.attachedWorkOrderFiles, ...files.computerFiles];
        }

        if (files?.linkedFiles?.length) {
          this.linkedProjectFiles = [...this.linkedProjectFiles, ...files.linkedFiles];
        }
      });
  }

  public removeAttachedFile(file: UhatFileReference): void {
    if (file && Array.isArray(this.attachedWorkOrderFiles)) {
      this.attachedWorkOrderFiles.splice(this.attachedWorkOrderFiles?.indexOf(file), 1);
    }
  }

  public removeLinkedProjectFile(file: UhatFileReference): void {
    if (file && Array.isArray(this.linkedProjectFiles)) {
      this.linkedProjectFiles.splice(this.linkedProjectFiles?.indexOf(file), 1);
    }
  }
}
